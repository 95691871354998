window.onload = function () {

  /* show / hide main nav
  -------------------------------------------------- */
  var btnnav = document.querySelector('.js-togglemenu');
  var mobilenav = document.querySelector('.js-navwrapper');

  btnnav.onclick = function () {
    btnnav.classList.toggle('active');
    mobilenav.classList.toggle('active');
  }

  var closenav = document.querySelectorAll('.js-closemenu')
  closenav.forEach(function (navbtn) {
    navbtn.addEventListener('click', function () {
      btnnav.classList.toggle('active');
      mobilenav.classList.toggle('active');
    })
  })

};
